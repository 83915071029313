/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react';
import { CapacityPopoverContainerProps } from './CapacityPopoverContainer';
import { OfficeUsage } from '../../store/office';

export const CapacityPopoverContainerLogic = ({
  officeId,
  dates,
  officeMap,
  getOfficeUsages,
}: CapacityPopoverContainerProps) => {
  const [officeUsages, setOfficeUsages] = useState<OfficeUsage[] | undefined>(undefined);
  const max = parseInt(officeMap.get(officeId)?.maxCapacity || '0');
  const officeName = officeMap.get(officeId)?.name || '';

  useEffect(() => {
    if (officeId && dates && dates.length > 0) {
      getOfficeUsages(officeId, dates).then((usages) => {
        setOfficeUsages(usages);
      });
    } else {
      setOfficeUsages(undefined);
    }
  }, [dates, officeId, getOfficeUsages]);

  return { max, officeName, officeUsages };
};

export const MockCapacityPopoverContainerLogic = ({
  officeId,
  dates,
  officeMap,
}: CapacityPopoverContainerProps) => {
  const [officeUsages, setOfficeUsages] = useState<OfficeUsage[] | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const max = parseInt(officeMap.get(officeId)?.maxCapacity || '0');
  const officeName = officeMap.get(officeId)?.name || '';

  useEffect(() => {
    if (officeId && dates && dates.length > 0) {
      const mockUsage: OfficeUsage[] = dates.map(
        (d) =>
          ({
            officeId: officeId,
            date: d,
            bookingCount: Math.floor(Math.random() * max).toString(),
          } as OfficeUsage),
      );

      setOfficeUsages(mockUsage);
    } else {
      setOfficeUsages(undefined);
    }
  }, [dates, officeId]);

  return { max, officeName, officeUsages };
};
